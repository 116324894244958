<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12" md="3">
        <b-card no-body>
          <v-select
            v-model="selectedLabel"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="labelOptions"
            :clearable="false"
            label="option"
            class="font-small-3"
          >
            <template #option="option">
              <span>{{ option.option }}</span>
            </template>
          </v-select>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card v-show="selectedLabel === 'Outlet'">
          <MyTable
            :columns="OutletColumns"
            :rows="OutletRows"
            :isLoading="isLoading"
            :customButton="customButtonOutlet"
            @customButton="handleOutlet('CREATE')"
            :hasExport="true"
            @handleExport="handleExport"
          >
            <template v-slot="{ props }">
              <span
                v-if="props.column.field === 'branch_id'"
                @click="goToLogPointDetails(props.row.id)"
              >
                <b-button variant="gradient-primary" block>
                  {{ !props.row.branch_id ? "N/A" : props.row.branch_id }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'name'">
                {{ !props.row.name ? "NULL" : props.row.name }}
              </div>
              <div v-if="props.column.field === 'comment'">
                {{ !props.row.comment ? "NULL" : props.row.comment }}
              </div>
              <div v-if="props.column.field === 'branch.address'">
                {{ !props.row.branch ? "NULL" : props.row.branch.address }}
              </div>
              <div v-if="props.column.field === 'email'">
                {{ !props.row.email ? "NULL" : props.row.email }}
              </div>
              <div v-if="props.column.field === 'phone'">
                {{ !props.row.phone ? "NULL" : props.row.phone }}
              </div>
              <div v-if="props.column.field === 'date_created'">
                {{ !props.row.date_created ? "NULL" : props.row.date_created }}
              </div>
              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 1"
                  block
                  variant="gradient-success"
                >
                  Active
                </b-button>
                <b-button v-else block variant="gradient-danger">
                  Inactive
                </b-button>
              </span>
              <span v-if="props.column.field === 'action'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleOutlet(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteOutlet(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </MyTable>
        </b-card>
        <b-card v-show="selectedLabel === 'Super Admin'">
          <MyTable
            :columns="HQColumns"
            :rows="HQRows"
            :isLoading="isLoading"
            :customButton="customButtonHQ"
            @customButton="handleHQ('CREATE')"
            :hasExport="true"
            @handleExport="handleExport"
          >
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button variant="gradient-primary" block>
                  {{ !props.row.id ? "N/A" : props.row.id }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'name'">
                {{ !props.row.name ? "NULL" : props.row.name }}
              </div>
              <div v-if="props.column.field === 'email'">
                {{ !props.row.email ? "NULL" : props.row.email }}
              </div>
              <div v-if="props.column.field === 'phone'">
                {{ !props.row.phone ? "NULL" : props.row.phone }}
              </div>
              <span v-if="props.column.field == 'role'">
                <b-button
                  v-if="props.row.role !== null"
                  variant="gradient-info"
                  block
                >
                  {{ props.row.role.role_name }}
                </b-button>
                <b-button v-else variant="warning" block> NULL </b-button>
              </span>
              <div v-if="props.column.field === 'date_created'">
                {{ !props.row.date_created ? "NULL" : props.row.date_created }}
              </div>
              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 1"
                  block
                  variant="gradient-success"
                >
                  Active
                </b-button>
                <b-button v-else block variant="gradient-danger">
                  Inactive
                </b-button>
              </span>
              <span v-if="props.column.field == 'action'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleHQ(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteHQ(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </MyTable>
        </b-card>
        <b-card v-show="selectedLabel === 'B2c User'">
          <MyTable
            :columns="HQColumns"
            :rows="B2cRows"
            :isLoading="isLoading"
            :customButton="customButtonB2c"
            @customButton="handleB2c('CREATE')"
            :hasExport="true"
            @handleExport="handleExport"
          >
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToLogPointDetails(props.row.id)"
                >
                  {{ props.row.id }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'name'">
                {{ !props.row.name ? "NULL" : props.row.name }}
              </div>
              <div v-if="props.column.field === 'email'">
                {{ !props.row.email ? "NULL" : props.row.email }}
              </div>
              <div v-if="props.column.field === 'phone'">
                {{ !props.row.phone ? "NULL" : props.row.phone }}
              </div>
              <span v-if="props.column.field == 'role'">
                <b-button
                  v-if="props.row.role !== null"
                  variant="gradient-info"
                  block
                >
                  {{ props.row.role.role_name }}
                </b-button>
                <b-button v-else variant="warning" block> NULL </b-button>
              </span>
              <div v-if="props.column.field === 'date_created'">
                {{ !props.row.date_created ? "NULL" : props.row.date_created }}
              </div>
              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 1"
                  block
                  variant="gradient-success"
                >
                  Active
                </b-button>
                <b-button v-else block variant="gradient-danger">
                  Inactive
                </b-button>
              </span>
              <span v-if="props.column.field == 'action'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleB2c(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteB2c(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>

    <!--Create Outlet-->
    <validation-observer ref="createOutletAdmin">
      <b-modal
        ref="createOutletModal"
        title="Create Outlet"
        hide-footer
        centered
        no-close-on-backdrop
        size="lg"
      >
        <form>
          <div>
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Outlet Name"
                  rules="required"
                >
                  <b-form-group label="Outlet Name">
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Outlet Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group label="Email Address">
                    <b-form-input
                      v-model="form.email"
                      type="text"
                      placeholder="Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-model="form.phone"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="branch_id"
                  rules="required"
                >
                  <b-form-group label="Select Outlet">
                    <select
                      v-model="form.branch_id"
                      class="form-control form-select rounded"
                    >
                      <option value="" disabled>Select Outlet</option>
                      <option
                        v-for="(branch, index) in branch"
                        :key="index"
                        :value="branch.id"
                      >
                        {{ branch.address ? branch.address : "" }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required|password"
                >
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="form.password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-form-group label="Confirm Password">
                    <b-form-input
                      v-model="form.confirm_password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <b-form-group label="Select Status">
                    <b-form-select
                      v-model="form.status"
                      :state="errors.length > 0 ? false : null"
                      :options="optionStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-center mb-2">
            <b-button
              variant="gradient-primary"
              @click.prevent="createOutletAdmin()"
            >
              SUBMIT
            </b-button>
          </div>
        </form>
      </b-modal>
    </validation-observer>

    <!--Edit Outlet-->
    <validation-observer ref="editOutletAdmin">
      <b-modal
        ref="editOutletModal"
        title="Edit Outlet"
        hide-footer
        centered
        no-close-on-backdrop
        size="lg"
      >
        <form>
          <div>
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group label="Outlet Name">
                    <b-form-input
                      v-model="editForm.name"
                      type="text"
                      placeholder="Outlet Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group label="Email Address">
                    <b-form-input
                      v-model="editForm.email"
                      type="text"
                      placeholder="Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-model="editForm.phone"
                      type="number"
                      placeholder="Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12 mb-2">
                <b-form-group label="Select Outlet">
                  <validation-provider
                    v-slot="{ errors }"
                    name="branch_id"
                    rules="required"
                  >
                    <select
                      v-model="editForm.branch_id"
                      class="form-control form-select rounded"
                    >
                      <option value="" disabled>Select Outlet</option>
                      <option
                        v-for="(branch, index) in branch"
                        :key="index"
                        :value="branch.id"
                      >
                        {{ branch.address ? branch.address : "" }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="password"
                >
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="editForm.password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="confirmed:Password"
                >
                  <b-form-group label="Confirm Password">
                    <b-form-input
                      v-model="editForm.confirm_password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <b-form-group label="Select Status">
                  <b-form-select
                    v-model="editForm.status"
                    :options="optionStatus"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-center mb-2">
            <b-button variant="gradient-primary" @click="editOutletAdmin">
              SUBMIT
            </b-button>
          </div>
        </form>
      </b-modal>
    </validation-observer>

    <!--Create Super Admin-->
    <validation-observer ref="HQAdmin">
      <b-modal
        ref="createHQModal"
        title="Create Super Admin"
        hide-footer
        centered
        no-close-on-backdrop
        size="lg"
      >
        <form>
          <div>
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group label="Name">
                    <b-form-input
                      v-model="formUser.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group label="Email Address">
                    <b-form-input
                      v-model="formUser.email"
                      type="text"
                      placeholder="Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-model="formUser.phone"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required|min:8|password"
                >
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="formUser.password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="required|min:8|confirmed:Password"
                >
                  <b-form-group label="Confirm Password">
                    <b-form-input
                      v-model="formUser.confirm_password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="User Type"
                  rules="required"
                >
                  <b-form-group label="Select User Type">
                    <v-select
                      v-model="selectedType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      placeholder="Add Access Level"
                      :options="optionType"
                      label="role_name"
                      :reduce="(optionType) => optionType"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <b-form-group label="Select Status">
                    <b-form-select
                      v-model="formUser.status"
                      :state="errors.length > 0 ? false : null"
                      :options="optionStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-center mb-2">
            <b-button variant="gradient-primary" @click.prevent="createHQAdmin">
              SUBMIT
            </b-button>
          </div>
        </form>
      </b-modal>

      <!--Edit Super Admin-->
      <b-modal
        ref="editHQModal"
        title="Edit Super Admin"
        hide-footer
        centered
        no-close-on-backdrop
        size="lg"
      >
        <form>
          <div>
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group label="Name">
                    <b-form-input
                      v-model="editFormUser.name"
                      type="text"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group label="Email Address">
                    <b-form-input
                      v-model="editFormUser.email"
                      type="text"
                      class="form-control"
                      placeholder="Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-model="editFormUser.phone"
                      type="number"
                      class="form-control"
                      placeholder="Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="password"
                >
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="editFormUser.password"
                      type="password"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="confirmed:Password"
                >
                  <b-form-group label="Confirm Password">
                    <b-form-input
                      v-model="editFormUser.confirm_password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="User Type"
                  rules="required"
                >
                  <b-form-group label="Select User Type">
                    <v-select
                      v-model="selectedType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      placeholder="Add Access Level"
                      :options="optionType"
                      label="role_name"
                      :reduce="(optionType) => optionType"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <b-form-group label="Status">
                  <b-form-select
                    v-model="editFormUser.status"
                    :options="optionStatus"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-center mb-2">
            <b-button variant="gradient-primary" @click.prevent="editHQAdmin">
              SUBMIT
            </b-button>
          </div>
        </form>
      </b-modal>
    </validation-observer>

    <!--B2c User Add, Update-->
    <validation-observer ref="B2cUser">
      <b-modal
        ref="createB2cModal"
        title="Create B2c User"
        hide-footer
        centered
        no-close-on-backdrop
        size="lg"
      >
        <form>
          <div>
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group label="Name">
                    <b-form-input
                      v-model="formUser.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group label="Email Address">
                    <b-form-input
                      v-model="formUser.email"
                      type="text"
                      placeholder="Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-model="formUser.phone"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required|min:8|password"
                >
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="formUser.password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="required|min:8|confirmed:Password"
                >
                  <b-form-group label="Confirm Password">
                    <b-form-input
                      v-model="formUser.confirm_password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <b-form-group label="Select Status">
                    <b-form-select
                      v-model="formUser.status"
                      :state="errors.length > 0 ? false : null"
                      :options="optionStatus"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-center mb-2">
            <b-button variant="gradient-primary" @click.prevent="createB2cUser">
              SUBMIT
            </b-button>
          </div>
        </form>
      </b-modal>

      <!--Edit B2c User-->
      <b-modal
        ref="editB2cModal"
        title="Edit B2c User"
        hide-footer
        centered
        no-close-on-backdrop
        size="lg"
      >
        <form>
          <div>
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group label="Name">
                    <b-form-input
                      v-model="editFormUser.name"
                      type="text"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group label="Email Address">
                    <b-form-input
                      v-model="editFormUser.email"
                      type="text"
                      class="form-control"
                      placeholder="Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-group label="Phone Number">
                    <b-form-input
                      v-model="editFormUser.phone"
                      type="number"
                      class="form-control"
                      placeholder="Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  rules="password"
                >
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="editFormUser.password"
                      type="password"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6 mb-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Confirm Password"
                  rules="confirmed:Password"
                >
                  <b-form-group label="Confirm Password">
                    <b-form-input
                      v-model="editFormUser.confirm_password"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12 mb-2">
                <b-form-group label="Status">
                  <b-form-select
                    v-model="editFormUser.status"
                    :options="optionStatus"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-center mb-2">
            <b-button variant="gradient-primary" @click.prevent="editB2cUser">
              SUBMIT
            </b-button>
          </div>
        </form>
      </b-modal>
    </validation-observer>
  </b-container>
</template>

<script>
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import exportFromJSON from "export-from-json";

const status = [
  { label: "Active", value: "active" },
  { label: "Scheduled", value: "scheduled" },
  { label: "History", value: "history" },
  { label: "Cancellation Request", value: "cancel_request" },
  { label: "Manually Assigned", value: "manually_assigned" },
  { label: "Request Refund", value: "request_refund" },
];

export default {
  components: {
    MyTable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  async created() {
    this.getAllOutletAdmin();
    this.getAllHQAdmin();
    this.getAllB2c();

    this.$http.get(`branch`).then((response) => {
      this.branch = response.data.data;
    });

    this.$http.get(`get_roles/1`).then((response) => {
      this.optionType = response.data.data;
      this.selectedType = this.optionType[0];
    });
  },

  methods: {
    goToLogPointDetails(id) {
      this.$router.push({
        name: "logpoint-details",
        params: {
          id: id,
        },
      });
    },

    goToLogPointDetails(id) {
      this.$router.push({
        name: "logpoint-details",
        params: {
          id: id,
        },
      });
    },

    handleExport(type) {
      if (this.selectedLabel === "Outlet") {
        const fileName = "generated-Outlet-admins";
        const data = this.OutletRows.map((item) => ({
          "Outlet ID": item.branch_id,
          "Outlet Name": item.name,
          "Outlet Address": item.branch?.address,
          "Email Address": item.email,
          "Phone Number": item.phone,
          "Date Created": item.date_created,
          Status: item.status === 1 ? "Active" : "Inactive",
        }));

        let exportType;
        switch (type) {
          case "xlsx":
            exportType = exportFromJSON.types.xls;
            exportFromJSON({ data, fileName, exportType });
            break;
          case "csv":
            exportType = exportFromJSON.types.csv;
            exportFromJSON({ data, fileName, exportType });
            break;
        }
      } else if (this.selectedLabel === "B2c User") {
        const fileName = "generated-b2c-users";
        const data = this.B2cRows.map((item) => ({
          "User ID": item.id,
          "User Name": item.name,
          "User Address": item.branch?.address,
          "Email Address": item.email,
          "Phone Number": item.phone,
          "Date Created": item.date_created,
          Status: item.status === 1 ? "Active" : "Inactive",
        }));

        let exportType;
        switch (type) {
          case "xlsx":
            exportType = exportFromJSON.types.xls;
            exportFromJSON({ data, fileName, exportType });
            break;
          case "csv":
            exportType = exportFromJSON.types.csv;
            exportFromJSON({ data, fileName, exportType });
            break;
        }
      } else {
        const fileName = "generated-HQ-admins";
        const data = this.HQRows.map((item) => ({
          "User ID": item.id,
          "User Name": item.name,
          "Email Address": item.email,
          "Phone Number": item.phone,
          Authority: item.role.role_name,
          "Date Created": item.date_created,
          Status: item.status === 1 ? "Active" : "Inactive",
        }));

        let exportType;
        switch (type) {
          case "xlsx":
            exportType = exportFromJSON.types.xls;
            exportFromJSON({ data, fileName, exportType });
            break;
          case "csv":
            exportType = exportFromJSON.types.csv;
            exportFromJSON({ data, fileName, exportType });
            break;
        }
      }
    },

    getAllOutletAdmin() {
      this.isLoading = true;
      this.$http.get(`users/Outlet`).then((response) => {
        if (response.data.status) {
          this.OutletRows = response.data.data;
        } else {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: response.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
        this.isLoading = false;
      });
    },

    getAllHQAdmin() {
      this.isLoading = true;
      this.$http.get(`users`).then((response) => {
        if (response.data.status) {
          this.HQRows = response.data.data;
        } else {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: response.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
        this.isLoading = false;
      });
    },

    getAllB2c() {
      this.isLoading = true;
      this.$http.get(`users/b2c`).then((response) => {
        if (response.data.status) {
          this.B2cRows = response.data.data;
        } else {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: response.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
        this.isLoading = false;
      });
    },

    handleOutlet(value) {
      switch (value) {
        case "CREATE":
          this.$refs.createOutletModal.show();
          break;
        default:
          this.form2 = { ...value };
          this.editForm.email = this.form2.email;
          this.editForm.id = this.form2.id;
          this.editForm.branch_id = this.form2.branch_id;
          this.editForm.branch_id = this.form2.branch_id;
          this.editForm.name = this.form2.name;
          this.editForm.phone = this.form2.phone;
          this.editForm.password = this.form2.password;
          this.editForm.confirm_password = this.form2.confirm_password;
          this.editForm.status = this.form2.status;
          this.$refs.editOutletModal.show();
          break;
      }
    },

    handleHQ(value) {
      switch (value) {
        case "CREATE":
          this.$refs.createHQModal.show();
          break;
        default:
          this.form3 = { ...value };
          this.editFormUser.email = this.form3.email;
          this.editFormUser.id = this.form3.id;
          this.editFormUser.name = this.form3.name;
          this.editFormUser.phone = this.form3.phone;
          this.editFormUser.password = this.form3.password;
          this.editFormUser.confirm_password = this.form3.confirm_password;
          this.editFormUser.status = this.form3.status;
          this.editFormUser.user_type = this.form3.role.id;
          this.selectedType = this.form3.role;
          this.$refs.editHQModal.show();
          break;
      }
    },

    handleB2c(value) {
      switch (value) {
        case "CREATE":
          this.$refs.createB2cModal.show();
          break;
        default:
          this.form3 = { ...value };
          this.editFormUser.email = this.form3.email;
          this.editFormUser.id = this.form3.id;
          this.editFormUser.name = this.form3.name;
          this.editFormUser.phone = this.form3.phone;
          this.editFormUser.password = this.form3.password;
          this.editFormUser.confirm_password = this.form3.confirm_password;
          this.editFormUser.status = this.form3.status;
          this.editFormUser.user_type = this.form3.role.id;
          this.$refs.editB2cModal.show();
          break;
      }
    },

    createOutletAdmin() {
      this.$refs.createOutletAdmin.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          const formData = new FormData();
          for (const key in this.form) {
            formData.append(key, this.form[key]);
          }
          this.$http.post(`user`, formData).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.form = {};
                  this.getAllOutletAdmin();
                  this.$refs.createOutletModal.hide();
                }
              });
            } else {
              this.$refs.createOutletAdmin.setErrors(response.data.errors);
            }
          });
          this.isLoading = false;
        }
      });
    },

    createHQAdmin() {
      this.formUser.user_type = this.selectedType.id;
      this.$refs.HQAdmin.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http.post(`user`, this.formUser).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.form = {};
                  this.formUser = {};
                  this.formUser = {};
                  this.getAllHQAdmin();
                  this.$refs.createHQModal.hide();
                }
              });
            } else {
              this.$refs.HQAdmin.setErrors(response.data.errors);
            }
            this.isLoading = false;
          });
        }
      });
    },

    createB2cUser() {
      this.$refs.B2cUser.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.formUser.user_type = 0;
          this.formUser.is_b2c = 1;
          this.$http.post(`user`, this.formUser).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.form = {};
                  this.getAllB2c();
                  this.$refs.createB2cUser.hide();
                }
              });
            } else {
              if (response.data.errors) {
                this.$refs.B2cUser.setErrors(response.data.errors);
              } else {
                this.$swal({
                  title: "Error !",
                  icon: "warning",
                  text: "Phone number already in use!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            }
            this.isLoading = false;
          });
        }
      });
    },

    editOutletAdmin() {
      this.$refs.editOutletAdmin.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .put(`user/${this.editForm.id}`, this.editForm)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: "Success !",
                  icon: "success",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.form2 = {};
                    this.getAllOutletAdmin();
                    this.$refs.editOutletModal.hide();
                  }
                });
              } else {
                this.$refs.editOutletAdmin.setErrors(response.data.errors);
              }
              this.isLoading = false;
            });
        }
      });
    },

    editHQAdmin() {
      this.editFormUser.user_type = this.selectedType.id;
      this.$refs.HQAdmin.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .put(`user/${this.editFormUser.id}`, this.editFormUser)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: "Success !",
                  icon: "success",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.form2 = {};
                    this.getAllHQAdmin();
                    this.$refs.editHQModal.hide();
                  }
                });
              } else {
                this.$refs.HQAdmin.setErrors(response.data.errors);
              }
              this.isLoading = false;
            });
        }
      });
    },

    editB2cUser() {
      this.$refs.B2cUser.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .put(`user/${this.editFormUser.id}`, this.editFormUser)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: "Success !",
                  icon: "success",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.form2 = {};
                    this.getAllB2c();
                    this.$refs.editB2cModal.hide();
                  }
                });
              } else {
                this.$refs.B2cUser.setErrors(response.data.errors);
              }
              this.isLoading = false;
            });
        }
      });
    },

    deleteOutlet(Outlet) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to delete ${Outlet.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-gradient-primary",
          cancelButton: "btn btn-outline-danger mr-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$http.delete(`user/${Outlet.id}`).then((response) => {
            if (response.data.status) {
              this.$swal({
                icon: "success",
                title: "Success !",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getAllOutletAdmin();
                }
              });
            } else {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
            }
          });
          this.isLoading = true;
        }
      });
    },

    deleteHQ(HQ) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to delete ${HQ.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-gradient-primary",
          cancelButton: "btn btn-outline-danger mr-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$http.delete(`user/${HQ.id}`).then((response) => {
            if (response.data.status) {
              this.$swal({
                icon: "success",
                title: "Success !",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getAllHQAdmin();
                }
              });
            } else {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
            }
            this.isLoading = false;
          });
        }
      });
    },

    deleteB2c(B2c) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to delete ${B2c.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-gradient-primary",
          cancelButton: "btn btn-outline-danger mr-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$http.delete(`user/${B2c.id}`).then((response) => {
            if (response.data.status) {
              this.$swal({
                icon: "success",
                title: "Success !",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getAllB2c();
                }
              });
            } else {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
            }
            this.isLoading = false;
          });
        }
      });
    },
  },

  data() {
    return {
      isLoading: true,
      branch: [],
      customButtonOutlet: {
        label: "Create Outlet",
        icon: "PlusCircleIcon",
      },
      customButtonHQ: {
        label: "Create Super Admin",
        icon: "PlusCircleIcon",
      },
      customButtonB2c: {
        label: "Create B2c User",
        icon: "PlusCircleIcon",
      },

      formUser: {
        name: "",
        email: "",
        phone: "",
        user_type: "",
        password: "",
        confirm_password: "",
        status: 1,
      },
      form: {
        name: "",
        email: "",
        phone: "",
        branch_id: "",
        password: "",
        confirm_password: "",
        status: 1,
      },
      form2: {},
      form3: {},
      editForm: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        branch_id: "",
        status: "",
      },
      editFormUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        status: "",
        user_type: "",
      },
      role: "",
      selected: 1,
      status,
      pageLength: 5,
      searchTerm: "",
      selectedLabel: "Super Admin",
      labelOptions: ["Super Admin", "Outlet", "B2c User"],
      selectedType: { role_name: "" },
      optionType: [],
      optionStatus: [
        { value: 1, text: "Active" },
        { value: 0, text: "In Active" },
      ],
      OutletColumns: [
        {
          label: "Outlet ID",
          field: "branch_id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Outlet Name",
          field: "name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Outlet Address",
          field: "branch.address",
          tdClass: "text-center align-middle",
        },
        {
          label: "Email Address",
          field: "email",
          tdClass: "text-center align-middle",
        },
        {
          label: "Phone Number",
          field: "phone",
          tdClass: "text-center align-middle",
        },
        {
          label: "Date Created",
          field: "date_created",
          tdClass: "text-center align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      HQColumns: [
        {
          label: "User ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "User Name",
          field: "name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Email Address",
          field: "email",
          tdClass: "text-center align-middle",
        },
        {
          label: "Phone Number",
          field: "phone",
          tdClass: "text-center align-middle",
        },
        {
          label: "Date Created",
          field: "date_created",
          tdClass: "text-center align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      B2cRows: [],
      OutletRows: [],
      HQRows: [],
      required,
    };
  },
};
</script>
